.gallery-container h1 {
    font-size: 48px;
    font-weight: 300;
    margin-bottom: 30px;
    color: #fff;
    padding-left: 100px;
  }
  .gallery {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    padding: 0 15px 0px;
  }
  .gallery .single-imgs {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
  }
  .single-pic {
    width: 100%;
  }
  .gallery .single-imgs:hover {
    transform: scale(1.2);
    filter: opacity(0.8);
  }
  @media (max-width: 991px) {
    .gallery {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
  }
  @media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
      padding: 0 5px 0px;
    }
    .gallery-container h1 {
      padding-left: 0;
    }
    .gallery-container {
      text-align: center;
    }
  }
  
  /*modal*/
  
  .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    transition: opacity 0.4s ease, visibility 0.4s ease,
      transform 0.5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
  }
  
  .model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  .model img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
  }
  .model.open .btn-close,
  .model.open .btn-next,
  .model.open .btn-prev {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: rgb(255, 255, 255);
    z-index: 999;
    font-size: 30px;
  }
  .btn-close:hover,
  .btn-next:hover,
  .btn-prev:hover {
    opacity: 1;
  }
  .model.open .btn-close {
    top: 40px;
    right: 40px;
  }
  .model.open .btn-prev {
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }
  .model.open .btn-next {
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }