@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
    background-image: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,1)), url(./assets/flock.webp);
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
}

html body {
    height: 100%;
    width: 100%;
    background-color: #fbfbfb;
}

.serif {
    font-family: 'Times New Roman', Times, serif;
}

.sprosans {
    font-family: 'Source Sans Pro', sans-serif;
}

.oswald {
    font-family: 'Oswald', sans-serif;
}

.monospace {
    font-family: 'Courier New', Courier, monospace;
}

.badscript {
    font-family: 'Bad Script', cursive;
}

.trans-png-dropshadow {
    -webkit-filter: drop-shadow(0px 0px 5px #000);
    filter: drop-shadow(0px 0px 5px #000);
}